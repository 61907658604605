@use 'styles/base/mixins';

.pill {
  @include mixins.text-small;

  display: inline-flex;
  margin-right: var(--spacing-02);
  padding: 10px var(--spacing-04);
  color: var(--white);
  background-color: #212427;
  white-space: nowrap;
  border-radius: 50px;

  &.active {
    color: var(--black);
    background-color: var(--white);
  }
}
